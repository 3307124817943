@import './button-reset.css';
:root {
  --ngx-contextmenu-focusable-border-bottom: 1px dotted #70757e;
  --ngx-contextmenu-font-family: sans-serif;
  --ngx-contextmenu-background-color: white;
  --ngx-contextmenu-border-radius: 4px;
  --ngx-contextmenu-border: 1px solid rgba(0, 0, 0, 0.18);
  --ngx-contextmenu-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
  --ngx-contextmenu-font-size: 14px;
  --ngx-contextmenu-margin: 2px 0 0;
  --ngx-contextmenu-min-width: 160px;
  --ngx-contextmenu-outline: 1px solid #70757e;
  --ngx-contextmenu-padding: 5px 0;
  --ngx-contextmenu-text-color: #70757e;
  --ngx-contextmenu-text-disabled-color: #8a909a;
  --ngx-contextmenu-item-arrow-left: '◀';
  --ngx-contextmenu-item-arrow-right: '▶';
  --ngx-contextmenu-item-background-hover-color: #f8f8f8;
  --ngx-contextmenu-item-separator-color: #8a909a;
  --ngx-contextmenu-item-separator-padding: 10px;
  --ngx-contextmenu-item-separator-width: 96%;
  --ngx-contextmenu-item-padding: 4px 10px;
  --ngx-contextmenu-item-text-hover-color: #5a6473;
}
context-menu-content:focus-visible {
  outline: var(--ngx-contextmenu-outline);
}
context-menu-content .ngx-contextmenu {
  font-family: var(--ngx-contextmenu-font-family);
}
context-menu-content .ngx-contextmenu ul.ngx-contextmenu--dropdown-menu {
  list-style: none;
  outline: none;
  background-color: var(--ngx-contextmenu-background-color);
  color: var(--ngx-contextmenu-text-color);
  min-width: var(--ngx-contextmenu-min-width);
  padding: var(--ngx-contextmenu-padding);
  margin: var(--ngx-contextmenu-margin);
  font-size: var(--ngx-contextmenu-font-size);
  text-align: start;
  background-clip: padding-box;
  border: var(--ngx-contextmenu-border);
  border-radius: var(--ngx-contextmenu-border-radius);
  box-shadow: var(--ngx-contextmenu-box-shadow);
}
context-menu-content .ngx-contextmenu ul.ngx-contextmenu--dropdown-menu .ngx-contextmenu--parent-menu:after {
  content: var(--ngx-contextmenu-item-arrow-right);
}
context-menu-content .ngx-contextmenu ul.ngx-contextmenu--dropdown-menu > li {
  display: flex;
}
context-menu-content .ngx-contextmenu ul.ngx-contextmenu--dropdown-menu > li:not([role='separator']) > * {
  flex: 1 1 auto;
}
context-menu-content .ngx-contextmenu ul.ngx-contextmenu--dropdown-menu > li > [role='separator'] {
  border-width: 0 0 1px 0;
  border-bottom: 1px solid var(--ngx-contextmenu-item-separator-color);
  margin: var(--ngx-contextmenu-item-separator-padding) 2%;
  width: var(--ngx-contextmenu-item-separator-width);
}
context-menu-content .ngx-contextmenu ul.ngx-contextmenu--dropdown-menu > li > [role='menuitem'] {
  padding: var(--ngx-contextmenu-item-padding);
}
context-menu-content .ngx-contextmenu ul.ngx-contextmenu--dropdown-menu > li > [role='menuitem'] {
  outline: none;
  color: var(--ngx-contextmenu-text-color);
  /*display: inline-flex;*/
  flex-direction: row;
  /*justify-content: space-between;*/
  text-decoration: none;
  white-space: nowrap;
  text-align: left;
}
context-menu-content .ngx-contextmenu ul.ngx-contextmenu--dropdown-menu > li > [role='menuitem']:not(.disabled):not(.ngx-contextmenu--item-content-passive):hover, context-menu-content .ngx-contextmenu ul.ngx-contextmenu--dropdown-menu > li > [role='menuitem']:not(.disabled):not(.ngx-contextmenu--item-content-passive).active {
  text-decoration: none;
  background-color: var(--ngx-contextmenu-item-background-hover-color);
  color: var(--ngx-contextmenu-item-text-hover-color);
}
context-menu-content .ngx-contextmenu ul.ngx-contextmenu--dropdown-menu > li > [role='menuitem'].disabled {
  cursor: default;
}
context-menu-content .ngx-contextmenu ul.ngx-contextmenu--dropdown-menu > li > [role='menuitem'].disabled, context-menu-content .ngx-contextmenu ul.ngx-contextmenu--dropdown-menu > li > [role='menuitem'].disabled:hover, context-menu-content .ngx-contextmenu ul.ngx-contextmenu--dropdown-menu > li > [role='menuitem'].disabled.active {
  color: var(--ngx-contextmenu-text-disabled-color);
}
context-menu-content .ngx-contextmenu[dir='rtl'] ul.ngx-contextmenu--dropdown-menu .ngx-contextmenu--parent-menu:after {
  content: var(--ngx-contextmenu-item-arrow-left);
}
